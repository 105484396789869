<script setup>
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

const storyblokApi = useStoryblokApi();
const { locale, locales } = useI18n();
const route = useRoute();
const router = useRouter();
const { getPage, handleSlug } = usePageManager();
const { cleanUp } = useUIControl()
const { slug } = route.params;
const { cached_data, content_version, editing, page_loading, getStory, getStoryDone } = useSettings("Page");
// const { content_version,  } = useConfig();

defineProps({ story: Object, default: () => ({}) });

let _slug = handleSlug(slug);
if (_slug.includes("pages/")) _slug = _slug.replace("pages/", "");

// console.log("changed page",_slug)
// // console.log("refresh",refreshData.value)
// if (process.server) {
//   // console.log("Loading story by slug : ", _slug, ` on ${process.server ? 'server' : 'client'} `)

// } else {
//   console.log(cached_data.value.active_story)
// }
//  page_loading.value = true
// const s = await useAsyncStoryblok(`pages/${_slug}`, {
//   version: content_version.value,
//   // resolve_relations: "link.story",
//   resolve_links: "url",
//   language: locale.value,
// });
// cached_data.value.active_story = s.value;

// if (cached_data.value.active_story?.slug != _slug || (cached_data.value.active_story?.slug != _slug && cached_data.value.active_story?.lang != locale.value)) {

// }
// console.log("slug", _slug, slug, "current", cached_data.value.active_story)
// console.log(locale.value)
// if (_slug == cached_data.value.active_story.slug && (locale.value == cached_data.value.active_story.lang || (locale.value == 'en' && cached_data.value.active_story.lang == "default")  )) { 
//   console.log("use cached data")
// } else {
// console.log(useAsyncStoryblok)
// let s = await useAsyncStoryblok(`pages/${_slug}`, {
//   version: content_version.value,
//   // resolve_relations: "link.story",
//   resolve_links: "url",
//   language: locale.value,
// });
// const resp = await useAsyncData(`story-${_slug}`, async () => {
//   return await storyblokApi.get(`cdn/stories/pages/${_slug}`, {
//     version: content_version.value,
//     // resolve_relations: "link.story",
//     resolve_links: "url",
//     language: locale.value,
//   })
//   // return st
// })
// if (_slug != "home") {
  
page_loading.value = true
  const { data, pending, error } = await useAsyncData(`page-${_slug}-${locale.value}`, async () => {
  try {
    // console.log("load async story")
    const response = await storyblokApi.get(`cdn/stories/pages/${_slug}`, {
      version: content_version.value,
      // resolve_relations: "link.story",
      resolve_links: "url",
      language: locale.value,
    })

    // console.log("storyloaded:", response.data.story.name)
    // Transform the data before returning it
    // getStoryDone(response.data.story);
    return response.data.story //|| cached_data.value.error_page
  } catch (err) {
    // console.log("trying to generate error")
    if (err.response?.status === 404) {
      throw createError({ statusCode: 404, statusMessage: 'Page not found' })
    }
    throw createError({ statusCode: 500, statusMessage: 'Internal Server Error' })
  }


}, { initialCache: true })
getStoryDone(data.value || cached_data.value.error_page)
// } else {
//   // console.log("use home")
//   getStoryDone(cached_data.value.home)
// }
// console.log(resp.data.value.data.story)
// console.log(_story)

// }
// await getStory(_slug);

//console.log("here!")
// if (process.server) {
//   useState('ccolor', () => {
//     // console.log("setting ccolor", cached_data.value.active_story?.content?.settings_header_color?.enabled ? cached_data.value.active_story?.content?.settings_header_color?.color : '#000' )
//     return cached_data.value.active_story?.content?.settings_header_color?.enabled ? cached_data.value.active_story?.content?.settings_header_color?.color : '#000'
//   })

// } else {
//   let c = useState('ccolor');

//   c.value = cached_data.value.active_story?.content?.settings_header_color?.enabled ? cached_data.value.active_story?.content?.settings_header_color?.color : '#000'

// }

const story = cached_data.value.active_story;
// console.log("[...slug].vue done")
//  console.log(story)
// console.log(story.content.meta_image?.filename || cached_data.value.meta.image?.filename || '')
page_loading.value = false

onBeforeUnmount(() => {
  cleanUp()
})
if (story) {
  let _title = `${story.content.title || story.name} | ${cached_data.value.meta.title}`
  let _description = story.content?.meta_description || cached_data.value.meta.description || ""

  useHead({
    title: _title,
    htmlAttrs: {
      lang: locale.value,
    },
    meta: [
      {
        name: "description",
        content: _description,
      },
      { hid: 'og-type', property: 'og:url', content: `https://ajvaccines.com/${_slug}` },
      { hid: 'og-type', property: 'og:type', content: 'website' },
      { hid: 'og-type', property: 'og:title', content: _title },
      { hid: 'og-type', property: 'og:description', content: _description },
      { hid: 'og-type', property: 'og:image', content: story.content.meta_image?.filename || cached_data.value.meta.image?.filename || '' },
    ],
    link: [
      { rel: 'canonical', href: `https://ajvaccines.com/${_slug}` }
    ],
    bodyAttrs: {},
  });
}
</script>

<template>
  <div class="content page" :class="`page--${story?.slug}`">
    <StoryblokComponent v-if="story && !story.message" :blok="story.content" :parent="story" />
  </div>
</template>
<style lang="scss">
.content {
  margin: 0px auto;

  &__inner {}
}

.page {
  transform: translate3d(0, 0, 0);
  // transition: opacity 1s cubic-bezier(0, 0.24, 0.51, 0.98);
}

.preloading-data {
  pointer-events: none;
}
</style>
